body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-size: 14px;
    font-weight: normal;
    color: #000;
}

#root {
    height: 100%;
    background-color: #f8f8f8;
}

.app {
    /* font-family: 'Montserrat'!important; */
    font-style: normal;
}

.layout {
    background: #fff;
    min-height: 100vh;
}

.content {
    margin-top: 100px;
}
.disable {
    cursor: not-allowed;
    pointer-events: none;
}
.text-center{
    text-align: center !important;
}
.loginBox {
    width: 452px;
    flex-grow: 0;
    padding: 50px 32px;
    border-radius: 15px;
    border: solid 1px #0d0f7b;
    background-color: #fff;
    padding-top: 24px!important;
}

.loginBox img {
    width: 242px;
    height: 47.5px;
    flex-grow: 0;
    margin: 0 0px 11.5px 0;
    object-fit: contain;
    margin-left: 20px
}

.loginBox input {
    width: 288px;
    height: 48px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    background-color: #fff !important;
}

.loginBox button {
    width: 288px!important;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    margin-top: 15px;
}

.loginCard {
    border: none!important;
}

.loginSpan {
    flex-grow: 0;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 800;
    text-align: left;
    color: #0d0f7b;
}
.email-input {
    margin: 0px;
}

.email-input input {  
    width: 100%;
    height: 46px;
    padding: 16px 12px;
    border-radius: 8px;
    font-size: 15px;
}


.ant-input-password {
    width: 100%;
    height: 46px;
    margin-top: 16px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    background-color: #fff !important;
    padding: 0px;
}

.ant-input-password input {
    height: 44px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px ​0px 10px 14px !important;
    text-indent: 10px;
    background-color: #fff !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ant-input-password .ant-input-suffix {
    padding-right: 14px;
}

.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #1251a5;  
    height: 48px;
    border-radius: 8px;    
    border-color: #1251a5;  
}

.header-login {
    height: 64.8px;
    padding: 12px 0;
    object-fit: contain;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    justify-content: center;
  }
.loginCard .ant-card-body {
    padding-left: 12px!important;
    padding-top: 36px!important;
}

.logoContainer {
    width: 100%;
    margin-bottom: 45px;
}

.head-bar { 
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.head-bar .header-btn{ 
    background: #fff;
    border: 0px;
    box-shadow: none;
    border-bottom: 2px solid #fff;
    border-radius: 0px;
    padding: 0px 0px;
    font-weight: 900;
    margin-right: 40px;
    box-shadow: 0;
}

.active-line,
.head-bar .header-btn:active,
.head-bar .header-btn:focus,
.head-bar .header-btn:hover {
    border: 0px;
    border-bottom: 2px solid #0d0f7b !important;
    color: #0d0f7b;
}

.tab-box {
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 6px 40px 5px;
    margin: 30px 10px 10px;
    border-radius: 56px;
    border: solid 2px;
    background-color: #fff;
    color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.upload-box {
    width: 466px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin: 0px 0px 20px 0px;
    padding: 32px;
    border-radius: 8px;
    background-color: #fff;
}

.upload-box .ant-divider-with-text {
    line-height: 1px !important;
}

.upload-title {  
    width: 200px;
    height: 21px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    align-self: normal;
    color: #131112;
    margin-left: 10px;
}

.upload-btn {
    width: 365px !important;
    height: 48px;  
    border-radius: 8px;
    background-color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.tedarikci-btn .upload-btn {
    width: 220px !important;
}

.tab-send-btn {
    width: 135px !important;
    height: 46.5px;
    border-radius: 0px;
    background-color: #1251a5;
    font-size: 14px;
    font-weight: bolder;
    margin-left: -1px;
    border-color: #1251a5;
    color: #fff;
}

.popup-send-btn {
    background-color: #0d0f7b;
    font-size: 14px;
    height: 46px;
    margin: 20px auto;
    font-weight: bolder;
    border-radius: 8px;
    border-color: #0d0f7b;
    color: #fff;
}

.popup-send-btn:hover, .popup-send-btn:focus, .popup-send-btn:active {
    background-color: #1251a5;
    border-color: #1251a5;
    color: #fff;
}

.header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0d0f7b;
}
.header .ant-tabs-ink-bar {
    background-color: #0d0f7b;
}

.tab-send-btn:hover, .tab-send-btn:focus, .tab-send-btn:active {
    background-color: #0d0f7b;
    border-color: #0d0f7b;
    color: #fff;
}

/* .upload-tabs .ant-tabs-nav {
    width: 895px !important;
} */

.upload-tabs {
    width: 100% !important;
}

.upload-tabs .ant-tabs-tab {
    background-color: #e6f1ff !important;
    color: #000 !important;
}

.upload-tabs .ant-tabs-tab-active {
    background-color: #0d0f7b !important;
    color: #fff !important;
}

.upload-tabs .ant-tabs-tab .ant-tabs-tab-remove{
    font-size: 18px;
    color: #000;
}

.upload-tabs  .ant-tabs-tab-active .ant-tabs-tab-remove{
    font-size: 18px;
    color: #fff;
}


.upload-tabs .ant-tabs-tab .upload-tab-title {
    margin: 0px; 
    color: #000;
}

.upload-tabs .ant-tabs-tab-active .upload-tab-title {
    margin: 0px; 
    color: #fff;
}

.upload-tabs .ant-tabs-nav-add {
    margin-left: -1px !important;
    padding: 0px !important;
}

.upload-tab-btn button {
    width: 165px !important;
    height: 46.5px;
    border-radius: 0px;
    background-color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
    margin-left: -1px;
    border-color: #0d0f7b;
}

.upload-tab-btn button:hover, .upload-tab-btn button:focus, .upload-tab-btn button:active {
    width: 165px !important;
    height: 46.5px;
    border-radius: 0px;
}

.tab-download-btn {
    width: 285px !important;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 17px;
    border-radius: 0px;
    background-color: #f4f4f4;  
    color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.download-btn {
    width: 285px ;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 17px;
    border-radius: 8px;
    background-color: #f4f4f4;  
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

.financeSpan {
    width: 100%;
    text-align: center;
    flex-grow: 0;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #107c41;
}

.welcomeSpan {
    width: 288px;
    height: 25px;
    flex-grow: 0;
    margin: 43px 0 11px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    margin-top: 45px;
}

.passwordBox {
    width: 288px;
}

.forgotPassword {  
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0d0f7b;
}

.forgotPassword:hover {
    color: #40a9ff;
}

.bigLogo {
    height: 35px;
    /* margin-left: 120px;
    margin-right: 120px;
    margin-top: 20px;
    margin-bottom: 20px; */
}

.header {
    width: 100%;
    height: 100%;
}

.w-100 {
    width: 100%;
}

.header .ant-tabs-nav {
    box-shadow: 0 2px 4px 0 rgba(231, 231, 231, 0.5);
    background-color: #fff;
    margin-bottom: 0px;
}

.header .ant-tabs-tab {
    margin-right: 50px;
}

.header .ant-tabs-content {
    height: 100%;
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-spin-container {
    height: 100%;
}

.table {
    padding-left: 20px;
    padding-right: 20px;
}

.table button {
    width: 100%;
    border-radius: 8px;
    background-color: #1a73e8;
}

.ant-table-cell {
    text-align: center;
    font-family: 'Montserrat'!important;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #202124;
}

.table .ant-table-thead .ant-table-cell {
    font-family: 'Montserrat'!important;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    background: #fff;
}

.tableTitle {
    display: block;
    font-family: 'Montserrat'!important;
    margin: 34px 22px 35px 80px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
}

.pageContainer {
    display: block;
    font-family: 'Montserrat'!important;
    margin: 34px 22px 35px 40px;
    min-height: 400px;
    padding-bottom: 40px;
    padding-top: 40px;
}

.pageTitle {
    display: block;
    margin: 20px 0px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
}

.newDemand {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e7792b;
}

.rejected {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cf1322;
}

.approved {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ad363;
}

.detailSpin {
    display: block;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
}

.detailSpin .default {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    margin-top: 30px;
}

.detailSpin .newDemand {
    color: #e7792b;
}

.detailSpin .rejected {
    color: #cf1322;
}

.detailSpin .approved {
    color: #5ad363;
}

.detailSpin .tableTitle {
    margin: 0px;
}

.detailSpin .detailsTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    display: block;
    margin-bottom: 15px;
}

.detailSpin .ant-table-cell {
    background: none;
    color: #8f8f8f;
    text-align: center;
    filter: grayscale(0.10);
    font-weight: bold;
}

.detailSpin tr {
    border-bottom-color: #dcdcdc;
}

.detailSpin thead tr {
    border-bottom-width: 2px!important;
}

.detailSpin .ant-table-cell img {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 8px;
}

.header .ant-tabs-tabpane-active {
    margin: 10px;
    width: calc(100% - 20px)!important;
}

.approveListButton {
    width: 152px;
    margin-left: 80px;
    padding: 0 7.2px 0 7.2px;
    border-radius: 8px;
    background-color: #1a73e8;
    height: 66px;
}

.detailRow {
    width: 100%;
}

.detailRow .col {
    padding-right: 60px;
    display: inline-block;
}

.detailRow .title {
    height: 20px;
    flex-grow: 0;
    margin: 0 0 2px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #202124;
    display: block;
}

.detailRow .value {
    height: 20px;
    flex-grow: 0;
    margin: 2px 0 0;
    font-size: 16px;
    text-align: left;
    color: #727272;
    filter: grayscale(0.10);
    display: block;
}

.detailSpin .invoiceControl {
    text-align: left;
    height: 40px;
}

.invoiceControl span {
    display: block;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
}

.invoiceControl .linkSpan {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: #1a73e8;
    margin-top: 5px;
    font-weight: 700;
}

.detailSpin .invoiceName {
    font-size: 12px;
    color: #727272;
}

.newDemandText {
    display: block;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    margin-top: 25px;
    margin-bottom: 25px;
}

.selectDetails {
    width: 280px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
}

.detailsDatePicker {
    width: 280px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
}

.detailsButton {
    height: 48px;
    width: 280px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    display: block;
    margin-top: 25px;
}

.invoiceImg {
    margin: 0px auto;
    height: 70vh;
    display: block;
}

.userDropdown {
    margin-right: 6em;
}

.userDropdownMenu {
    min-width: 140px!important;
    top: 53px !important;
}

.ant-dropdown-link {
    color: #0d0f7b;
}

.dosyaBtn {
    height: 54px;
    width: 180px;
    border-radius: 5px;
}

.supplierBtn {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    height: 35px;
    margin-right: 5px;
}

.ml {
    margin-left: 10px;
}

.excelUploadTable {
    margin-left: -70px;
}

.excelFileBox {
    display: flex;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: rgba(0,0,0,.85);
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 10px 20px;
    width: max-content;
    border-radius: 6px;
    align-items: flex-end;
    margin-bottom: 10px;
}

.excelFileText {
    display: flex;
}

.excelFileIcon {
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    color: #1a73e8;
}

.ant-table-cell {
    text-align: left;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
    border-radius: 0;
    margin-right: 8
}

.ant-table-filter-dropdown-btns .ant-btn {
    width: 90px !important;
    height: 24px !important;
}

.username-box {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    /* margin: 5px 0 5px 20px; */
    /* padding: 6px 9px 4px 10px; */
    border-radius: 25px;
    background-color: #0d0f7b;
    color: #fff;
    font-size: 1.4em;
    font-weight: 500;
}

.suplier-sideBar {
    width: 320px;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14px 20px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
}

.active {  
    background-color: #f0f7ff;
}

.side-item {  
    width: 100%;
    height: 48px;
    padding: 10px 0px 10px 20px;  
    color: #0d0f7b !important;  
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
}

.text-blue {
    color: #0d0f7b !important;  
}


.limit-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    color: #001444;
    margin-bottom: 10px;
}

.total-limit-box {  
    width: 100%;
    /* height: 131px; */
    padding: 25px 20px 27px 24px;
    /* background-color: #fafbff; */
    border-radius: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.total-limit-box .ant-form-item{     margin: 0;}

.limit-box {    
    height: max-content;
    padding: 0px 0px 0px;
    margin: 35px 0px 20px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
    background-color: #fafbff;
    align-items: baseline;
}

.limit-table-box {    
    height: 560px;
    padding: 0px 0px 10px;
    margin: 20px 0px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
    background-color: #fff;
}

.limit-input {      
    width: 195px;
    height: 50px;
    margin: 8px 0px 0 0;
    padding: 16px 16px 15px;
    border-radius: 8px;
    border: solid 1px #727272;
    background-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.limit-input::-webkit-outer-spin-button,
.limit-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.limit-input[type=number] {
  -moz-appearance: textfield;
}

.limit-link {
    /* margin: 23px 0 19px 24px; */
    margin-left: 15px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #1251a5;
    text-decoration: underline;
}

.limit-btn {  
    width: 380px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 28px 0px;
    padding: 16px;
    border-radius: 8px;
    background-color: #0d0f7b;
    color: #fff;
}

.supplier-table .ant-table-cell:last-child {
    text-align: left;
}

.files-drop {
    width: 215px;
    margin-left: 0px;
    margin-top: 0px;
}

.files-dp { 
    background: #e6f1ff;
    height: 48px;
    width: 215px;
    padding: 12px;
    color: #001444; 
    margin: 0px;
}

.upload-tabs .ant-tabs-nav-more {
    padding: 0px!important;
}
footer{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    position: relative;
    background-color: #fff;
    margin-top: 3rem;
    padding-top: 20px;
}
.footer-box {
    /* background-color: #001444;
    color: #fff;
    width: 100%;
    padding: 25px 50px 25px 50px; */
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}
@media screen and (min-width: 640px) {
    .footer-box  {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
.footer-bottom-link {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
@media screen and (min-width: 640px) {
    .footer-bottom-link {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}
.footer-bottom-link a {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.25rem;
    margin: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}


.footer-box img .ref-logo {
    height: 24px;
    max-width: 100%;
}

.footer-box .txt {
    font-size: 12px;
    display: grid;
    gap: 12px;
    font-family: Montserrat, sans-serif;
}
.footer-box .lnt {
    font-size: 14px;
    display: grid;
    gap: 24px;
    text-align: left;
    font-family: Montserrat, sans-serif;
}
.footer-box .fnt {
    font-size: 14px;
    display: grid;
    gap: 24px;
    text-align: right;
    font-family: Montserrat, sans-serif;
}
.big-mr {
    margin-right: 80px;
}
.mr-bt {
    margin-bottom: 24px;
}

.left-txt {
    text-align: left;
    color: #fff;
    font-family: Montserrat, sans-serif;
}

.right-txt {
    text-align: right;
    color: #fff;
    font-family: Montserrat, sans-serif;
}

.bold {
    font-weight: 'bold';
}

.text-white {
    color: #fff;
}

.hint-popup .next-btn {
    width: 141px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 56px;
    border-radius: 8px;
    background-color: #0d0f7b;
    color: #fff;
    margin: 10px 0px 0px auto;
}

.hint-modal-btn {
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 56px;
    border-radius: 8px;
    margin-top: 30px;
}

.start-btn {
    width: 116px;
    background-color: #0d0f7b;
    color: #fff;
    margin-left: 20px;
}

.hint-modal-btn:hover, .hint-modal-btn:focus, .hint-modal-btn:active {
    height: 40px;
}

.skip-btn {
    width: 79px;
    background-color: #e9f3ff;
    color: #000;
}

.hint-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.black-sheet {
    width: 100%;
    height: 100vh;
    margin: 0 2px 0 0;
    padding: 64px 122px 33px 108px;
    opacity: 0.4;
    background-color: #131112;
    position: fixed;
    z-index: 1;
}

.hint-dp1 {
    top: 51px !important;
    left: 255px !important;
}

.active-hint {
    z-index: 1;
    padding: 0px 20px;
    border-radius: 5px;
}

.react-joyride__tooltip {
    width: 450px !important;
}

.react-joyride__tooltip div:first-child{
    text-align: left !important;
}

.react-joyride__tooltip div:first-child div{
    padding: 0px 5px 0px!important;
}

.react-joyride__beacon span {
    background-color: #0d0f7b !important ;
    border-color: #0d0f7b !important ;
}

.delete-pop button {
    height: 32px !important;
    width: max-content;
    border-radius: 8px;
    padding: 4px 20px;
}

/* HEADER CSS */
header{
    /* background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%); */
    display: flex;
    /* padding: 20px 50px; */
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    position: relative;
    background-color: #fff;
    flex-direction: column;
}
.header-top{
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    padding: 8px 0 4px ;
}
.header-bottom{
    padding-top: 10px;
    width: 100%;
    display: flex;
    height: 58px;
}
.header-center{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.header-center-bottom{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    flex-direction: row;
}
.header-logo{
    width: 300px;
    height: 100%;
}
.header-menu{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: end;
    justify-content: end;
}
header .use-account{
    display: flex;
    align-items: center;
    justify-content: center;
   
}

@media screen and (min-width: 640px) {
    .header-center {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
header .user-mail{
    padding: 4px 10px;
    background-color:#c05381;
    line-height: 28px;
    color:#fff
}
header .user-mail svg{
   font-size: 18px;
   font-weight: bold;
   color:#fff
}
header .user-notification{
    padding: 4px 8px;
    background-color:#d283a4cc;
    line-height: 28px;
    color:#fff
}

header .user-notification svg{
    font-size: 18px;
    font-weight: bold;
    color:#fff
 }

 header .user-search{
    padding: 4px 8px;
    background-color:#d59bb3cc;
    line-height: 28px;
    color:#fff
}

header .user-search svg{
    font-size: 18px;
    font-weight: bold;
    color:#fff
 }

header .account{
    /* background-color:#f67be5ba; */
    line-height: 24px;
    padding: 2px 7px;
    color:#fff
}
.header-logo{
    display: block;
    position: relative;
}
.ant-menu {
    color: #983961 !important;
  background-color: none !important;
}
.user-menu-box{
    display: flex;
}
.user-content{
    display: flex;
    flex-direction: column;
}
.user-content dl{
    display: flex;
    flex-direction: row;
    margin: 0;
    font-size: 12px;
    justify-content: space-between;
}
.user-menu-box-avatar{
    margin-right: 15px;
}
.user-title{
    font-weight: bold;
    padding-bottom: 15px;
   
}
header .header-top .ant-btn {
    height: auto;
    border-radius: 0;
    min-width: auto;
    font-weight: 500;
    background: none !important;
    border: none !important;
    color: #fff !important;
    padding: 0 !important;

}

header .mobile-menu .ant-btn{
    height: 38px;
    border-radius: 4px;
    width: 47px !important;
    font-weight: 500;
    min-width: 15px !important;
    
}
header .ant-menu-overflow-item{
    position: relative !important;
}

.user-content{
    margin-top: 10px;
}
.ant-badge .ant-badge-count{
    position: absolute;
    top: -6px !important;
    background: #f29be5;
    z-index: auto;
    min-width: 16px;
    height: 16px;
    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
}
 .ant-badge .ant-badge-count{
    position: absolute;
    top: -6px !important;
    background: #f29be5;
    z-index: auto;
    min-width: 16px;
    height: 16px;
    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
}

.ant-menu-horizontal {
    line-height: 50px;
    border: none !important;
    border-bottom: none !important;
    box-shadow: none;}


.ant-menu-submenu::after {
        position: relative;
        inset-inline: 16px;
        bottom: 0;
        border-bottom: none !important;
        transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        content: "";
    }
 .ant-menu-submenu-selected::after {
        border-bottom-width: none;
        border-bottom-color: #1677ff;
        border: none !important;
    }

.header-menu a{
    color: #983961 !important;
    font-weight: 600;
}
/* Altı çizili durumu kaldır */
.ant-menu-horizontal .ant-menu-item:hover,
.ant-menu-horizontal .ant-menu-submenu-title:hover {
  text-decoration: none !important;
}

/* Aktif menü öğesinin gri olmasını sağla */
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #d9d9d9 !important;
  color: #000000 !important;
}

/* Hover durumunda altı çizili durumu kaldır ve arka plan rengini değiştir */
.ant-menu-horizontal .ant-menu-item-active,
.ant-menu-horizontal .ant-menu-submenu-active {
  background-color: inherit;
  color: inherit;
  text-decoration: none;
}

.ant-menu-horizontal .ant-menu-item::before,
.ant-menu-horizontal .ant-menu-item::after,
.ant-menu-horizontal .ant-menu-submenu-title::before,
.ant-menu-horizontal .ant-menu-submenu-title::after {
  display: none;
}

.ant-menu-horizontal .ant-menu-item-active,
.ant-menu-horizontal .ant-menu-submenu-active {
  background-color: inherit;
  color: inherit;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #d9d9d9 !important;
  color: #000000 !important;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  white-space: normal;  /* Satır kaydırmayı sağlar */
  overflow: visible;    /* Taşma olduğunda gizlemeyi önler */
  text-overflow: clip;  /* Taşma metnini keser ama ellipsis eklemez */
}
 .bigLogo{
    height: auto;
    width: 270px;
  margin:0 ;
}

header nav{
    display: block;
}
header nav a{
    line-height: 64px;
}
header nav button{
    background: rgb(255, 255, 255) !important;
    border: 0px !important;;
    box-shadow: none !important;
    border-radius: 0px !important; 
    padding: 0px !important;
    font-weight: 600 !important;
    margin-right: 40px !important;
    color: rgb(13, 15, 123) !important;
    user-select: none !important;
   
    font-size: 14px !important;
}
header nav button:hover
{
    border-width: 0px 0px 2px !important; 
    border-top-style: initial !important;
    border-right-style: initial !important;
    border-left-style: initial !important;
    border-top-color: initial !important;
    border-right-color: initial !important;
    border-left-color: initial !important;
    border-image: initial !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgb(13, 15, 123) !important;
}
.desktop-menu {
    display: block;
  }
  .mobile-menu {
    display: none ;
  }

  @media screen and (max-width: 768px) {
    .desktop-menu {
      display: none;
    }
    .mobile-menu {
      display: block ;
    }
  }

  /* Main */

  main{
    display: flex;
   min-height: calc(100% - 360px);
    /* width: 1200px; */
    max-width: 100%;
    /* margin: 81px auto 60px; */
    min-height: 450px;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
  }
  main .page{
    display: block;
    width: 100%;
    height: 100%;
    max-width: 922px;
  }

  @media screen and (max-width: 1200px){
    main{
        padding-left: 30px;
        padding-right: 30px;
    }
  }
  .page-title{
    font-weight: 600 ;
    color: rgb(13, 15, 123) ;
    font-size: 16px ;
  }
  .row{
    display: flex;
  }

  /* my suppliers css */
  .my-suppliers{
    margin-top: 50px;
  }
  .my-suppliers .suplier-sideBar .side-item{
    cursor: pointer;
  }
  .tab-box{
    cursor: pointer;
  }
  .my-suppliers .my-suppliers-right .tab-box{
    cursor: pointer;
  }
  @media screen and (max-width: 1280px){
    .my-suppliers{
        display: block !important;
        margin-top: 0 !important;
    }
    .my-suppliers .suplier-sideBar{
        width: 100%;
        max-width: 100%;
        margin-bottom: 40px;
      }
      .my-suppliers .my-suppliers-right{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
  }


/* supplier limit */

/* Dashboard */



.ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;
}
.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, 
.ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover,
 .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #c05381; }
 .ant-menu-submenu-selected {
        color: #c05381;}
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
            color: #c05381;
        }
.ant-menu-item a:hover {
            color: #c05381;
        }
.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
border-right: 3px solid #c05381;

}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background-color: #c05381;
}
nav .ant-space-align-center{
    gap:0 !important
}


.ant-btn-primary {
    height: 38px;
    border-radius: 8px;
    border-color: #c05381;
    min-width: 100px;
}
.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #c05381;
    height: 38px;
    border-radius: 8px;
    border-color: #c05381;
    min-width: 100px;
}

.ant-modal-body {
    padding-bottom: 10px;
    padding-top: 40px;
}
.supplier-transaction .ant-modal-body {
    padding-bottom: 4px;
    padding-top: 17px;
}
.ant-modal-body p {
    font-size: 14px;
}
.ant-btn{
    height: 38px;
    border-radius: 8px;
    min-width: 100px;
    font-weight: 500;
}
.margin-bottom{
    margin-bottom: 35px;
}
.default-form .ant-form-large .ant-form-item-label>label {
    height: 25px;
    font-size: 14px;
    font-weight: 500;
}
.default-form  .ant-form-item-label{
    padding-bottom: 0
}
.default-form .ant-form-item{
    margin: 0 0 10px;
}
.default-form .ant-form-large .ant-form-item-label>label{
    height: 25px !important;
}
.ant-form-item-label>label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px !important;
    color: rgba(0,0,0,.85);
    font-size: 14px !important;
} 
.ant-input-lg {
    padding: 6.5px 11px;
    font-size: 13px;
}
.ant-btn-lg {
    font-size: 14px !important;
}
.ant-input {
    border-radius: 6px !important;
}
.ant-select-lg {
    font-size: 14px !important;
}



/* ------ */
.introductory-text{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    border-radius: 1rem;
    -webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    box-shadow: 1px 1px 5px 0 rgba(1, 1, 1, .05);
    
}
.introductory-text h2 , p , h5{ margin: 0; padding: 0;}
.introductory-text p {line-height: 20px;}
.align-center{ text-align: center;}

/* Blog card */
.blog-card{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}
@media screen and (min-width: 640px) {
    .blog-card {
        max-width: none;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0.5rem;
    }
}
.blog-card  .cardx{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: var(--theme-ui-colors-contentBg, #fff);
    border-radius: 1rem;
    -webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    box-shadow: 1px 1px 5px 0 rgba(1, 1, 1, .05);
    overflow: hidden;
    height: 100%;
}
.blog-card  .cardx .article{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.blog-card  .left-img{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: var(--theme-ui-colors-alpha, #667eea);
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    margin: 0.5rem;
    margin-right: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    position: relative;
    display: block;
}
@media screen and (min-width: 640px) {
    .blog-card  .left-img{
        -webkit-flex-basis: 40%;
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
        margin: 0.5rem;
        margin-right: 0;
    }
}
.blog-card .right-txtt{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 1rem;
}
@media screen and (min-width: 640px) {
    .blog-card .right-tx {
        padding: 2rem;
    }
}
.blog-card  .card-title{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: bold;
    line-height: 1.33;
    display: block;
    color:  #2d3748;
    font-weight: bold;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 1rem;
    display: block;
    color:  #2d3748;
    font-weight: bold;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    text-align: right;
}

@media screen and (min-width: 640px) {
    .blog-card  .card-title {
        display: block;
        color: var(--theme-ui-colors-heading, #2d3748);
        font-weight: bold;
        -webkit-text-decoration: none;
        text-decoration: none;
        margin-bottom: 0;
        font-size: 1.25rem;
        -webkit-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }
}

@media screen and (min-width: 640px) {
    @media screen and (min-width: 768px) {
        .blog-card  .card-title {
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
        }
    }
}
.box-card{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: none;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0.2rem;
}
.box-card-div{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: var(--theme-ui-colors-contentBg, #fff);
    border-radius: 1rem;
    -webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    box-shadow: 1px 1px 5px 0 rgba(1, 1, 1, .05);
    overflow: hidden;
    height: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .box-card-div:hover {
        -webkit-transform: translateY(-0.15rem);
        -ms-transform: translateY(-0.15rem);
        transform: translateY(-0.15rem);
        box-shadow: 0px 1px 2px rgba(46, 41, 51, 0.08), 0px 1px 5px rgba(71, 63, 79, 0.16);
    }
}

.box-card-article{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #983961;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.box-card-link{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-height: 80px;
    padding:0.4rem  1rem;
    
}
.box-card-info{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 0.75rem;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    padding: 14px;
    gap: 15px;
    min-width: 300px;
    max-width: 300px;
}
.box-card-link a{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: bold;
    line-height: 1.33;
    display: block;
    color: #2d3748;
    font-weight: bold;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 1rem;
    display: block;
    font-weight: 600;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 0;
    font-size: 1rem;
}
.information-div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.box-card-staticyclic{
    display: flex;
    gap: 20px;
}
.box-card-staticyclic span{
    font-size: 11px;
}
 pre{
    font-size: 10px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    margin: 4px 0px;
    color: #a0aec0;
}

.box-card-info .box-title a{
    color:#983961 !important;
}
.box-card-info .box-time {
    color:#a0aec0 !important;
    font-size: 11px;
}
.box-card-info .box-user {
    color:#983961 !important;
    font-weight: bold;
}
.box-icon{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}
.box-icon .icon-icon{
    margin-left: 10px;

}
.card-bloc-ttle{
    display: flex;
    flex-direction: column;
}
.card-bloc-ttle pre{
    color: #6e7276;
}
.card-bloc-ttle span{
    color: #6e7276;
    font-size: 10px;
}
.right-card{
    margin-top: 6px;
}
.header-search-btn{
    height: auto;
    border-radius: 8px;
    min-width: auto;
    font-weight: 500;
    background-color: #c05381;
    border: none !important;
    color: #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.header-search-btn:hover {
    color: #fff !important;
    border:none !important;
}
.seach-form input{
    font-size: 13px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c05381;
    border-color: #c05381;
}
.ant-radio-checked  .ant-radio-inner {
    background-color: #c05381;
    border-color: #c05381;
}
.ant-form-item-label{
    padding: 8px 0 0px !important;
}
.ant-input-password {
    margin-top: 0;
}

/* Editör css */
.editor-css .ant-form-item{ margin-bottom: 15px;}
.ql-editor{
    min-height: 240px;
}
.top-categry .top-categry-span{
    color:#c05381
}
.ant-select-selection-item pre{ display: none;}
.editor-css .ant-input {
    color: #c05381;
}
.handle{
    cursor: pointer;
}

/* Topic list msg blog */
.topic-user-profil .ant-card {
    font-size: 12px;
}
.topic-list-div{
    flex-direction: row !important;
}
.topic-user-profil{
    width: 200px;
}
.topic-user-profil .ant-card .ant-card-body{
    padding: 15px 0 0 10px !important;
}
.topic-user-profil .ant-space-gap-col-small {
    column-gap: 2px;
}
.topic-user-profil .ant-space-gap-row-small {
    row-gap: 0;
}

.topic-user-profil  .ant-card-body{
min-height: 280px;
}   
.topic-user-profil .ant-ribbon{
    font-size: 12px;
}
.topic-user-profil .t-u-span{
    font-size: 11px;
    margin-left: 0;
    line-height: 13px;
}
.topic-user-profil .user-information{
    width: 100%;
    align-items: flex-start;
}
.topic-user-avatar{
    width: 80px;
    height: 80px;
    line-height: 70px;
    font-size: 40px;
    background-color: rgb(196, 196, 163);
    border: 4px solid #fff;
}
.topic-user-avatar-space .online{
    border: 4px solid #52c41a;
}
.topic-user-avatar-space .offline{
    border: 4px solid #ff0000;
}
.topic-msj{
    /* border:1px dashed; */
    width:calc(100% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.topic-msj-date{
    background-color: #f6f3f3;
    padding:4px 10px;
    font-size: 11px;
    font-style: italic;
    border-radius: 4px;
}
.topic-msj-btn{
    background-color: #f6f3f3;
    padding:4px 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
}
.topic-msj-btn .btn-right .ant-btn{
    background: none;
    border: none;
    height: auto;
    width: auto;
    min-width: 0;
    color:#983961
}
.topic-msj-answer{
    background-color: #f6f3f3;
    padding: 10px 15px 15px 20px;
    margin: 10px 0;
    font-style: italic;
    position: relative;
    margin-left: 0;
    border-radius: 5px;
}
.topic-msj-answer span{
    color:#983961;
    padding-bottom: 8px;
    width: 100%;
    display: block;
    font-weight: bold;
}
.topic-msj-txt{
    padding:15px 10px;
    padding-bottom: 0;
}
.topic-msj-txt p{
    margin-bottom: 15px;
}
.topic-like{
    margin-top: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.topic-like button{
    border: 1px solid;
    background: none;
    border: none;
    height: auto;
    width: auto;
    min-width: 0;
    color:#983961;
    font-size: 12px;
    width: 20px !important;
    margin: 0;
    padding: 0;
    line-height: 12px;
}
.topic-like-list{
    padding: 4px 0 !important;
    font-size: 12px;
    display: inline;
    border:none !important;
    display: flex;
    align-items: center;
}
.topic-like .ant-list-split .ant-list-item {
    border:none !important;
    
}
.lile-span{
    font-size: 12px;
    padding-right: 5px !important;
    color:#983961 !important;
    font-size: 12px;
    display: inline !important;
    border:none !important
}
.reply-modal .ant-modal-body{
    padding-top: 15px;
}
.ant-pagination {
    font-size: 13px;
    text-align: left !important;
    background: #fff;
    padding: 4px 6px;
    border-radius: 6px;
    border-bottom: 1px solid #f0f0f0;
}
.ant-pagination .ant-pagination-item-active a {
    color: #983961;
}
.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #ffffff;
    border-color: #983961;
}
.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #ffffff;
    border-color: #983961;
}
.ant-pagination .ant-pagination-item-active:hover {
    border-color: #983961;
}
.ant-pagination .ant-pagination-item-active:hover a {
    color: #983961;
}
.topic-page-title{
    margin-bottom: 30px;
}
.topic-page-title h2{
    margin-bottom: 0;
}
.ant-breadcrumb li:last-child a{
    color: #983961;
    font-weight: 600;
}

/* Submenu */
.submenu-style{
    margin: 40px auto 10px;
    justify-content: center;
    line-height: 44px;
    width: 60%;
    border-radius: 6px;
}